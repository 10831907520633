import { gql } from '@apollo/client';

export const ALL_CATEGORIES = gql`
  query fetchCategories($query: CategoryWhereInput) {
    fetchCategories(query: $query) {
      categories {
        id
        category
        isActive
        imageUrl
        createdAt
        updatedAt
        GoogleCategoryMappings {
          googleCategoryName
        }
      }
    }
  }
`;

export const ALL_GOOGLE_CATEGORIES = gql`
  query fetchGoogleCategoryMappings {
    fetchGoogleCategoryMappings {
      name
    }
  }
`;
