import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { REQUEST_EMAIL_MUTATION, VERIFY_CODE_MUTATION } from './login.mutation';

export enum EmailSignInStatus {
  initial,
  codeSent,
  verifying,
}

export enum EmailSignInError {
  emailSendingFailure,
  otpVerificationFailure,
}

export const useSignInWithEmail = (): [
  () => void,
  (email: string, phone: string) => void,
  (code: string) => void,
  () => void,
  EmailSignInStatus,
  EmailSignInError | null,
  string | null,
] => {
  const [signInStatus, setSignInStatus] = useState(EmailSignInStatus.initial);
  const [loggedInUser, setLoggedInUser] = useState<string | null>(null);
  const [error, setError] = useState<EmailSignInError | null>(null);
  const [emailVerificationSid, setEmailVerificationSid] = useState<
    string | null
  >(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  const [
    requestEmail,
    {
      data: requestEmailData,
      error: requestEmailError,
      loading: requestEmailLoading,
    },
  ] = useMutation(REQUEST_EMAIL_MUTATION);

  const [
    verifyOtpCode,
    {
      data: verifyCodeData,
      loading: verifyCodeLoading,
      error: verifyCodeError,
    },
  ] = useMutation(VERIFY_CODE_MUTATION);

  const initialize = useCallback(() => {}, []);

  const signInWithEmail = useCallback(
    (email: string, phone: string) => {
      setEmail(email);
      setPhone(phone);
      requestEmail({
        variables: {
          email,
        },
      });
      setSignInStatus(EmailSignInStatus.codeSent);
    },
    [requestEmail],
  );

  useEffect(() => {
    if (requestEmailLoading || requestEmailError || !requestEmailData) {
      return;
    }
    if (requestEmailData) {
      console.log('requestEmailData', requestEmailData);
      setEmailVerificationSid(requestEmailData.requestEmail);
    }
  }, [requestEmailData, requestEmailError, requestEmailLoading]);

  const verifyCode = useCallback(
    (code: string) => {
      setSignInStatus(EmailSignInStatus.verifying);
      verifyOtpCode({
        variables: {
          phone: phone,
          email: email,
          verificationSid: emailVerificationSid,
          code,
        },
      });
    },
    [email, emailVerificationSid, phone, verifyOtpCode],
  );

  useEffect(() => {
    if (verifyCodeLoading || verifyCodeError || !verifyCodeData) {
      return;
    }
    console.log('verifyCodeData', verifyCodeData);
    setLoggedInUser(verifyCodeData.verifyCode);
  }, [verifyCodeData, verifyCodeError, verifyCodeLoading]);

  const reset = useCallback(() => {
    setSignInStatus(EmailSignInStatus.initial);
    setError(null);
    initialize();
  }, [initialize]);

  return [
    initialize,
    signInWithEmail,
    verifyCode,
    reset,
    signInStatus,
    error,
    loggedInUser,
  ];
};
