import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, colors } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useToast } from '../../common/hooks/useToast';
import { CATEGORIES } from '../../graphql/queries';
import { BUSINESS_BY_PLACE_ID } from '../businessDetails/businessDetails.queries';
import { UPDATE_BUSINESS } from './business.mutation';

interface PropsInterface {
    business: any;
    handleClose: (isSuccess?: number) => void;
}

const schema = z.object({
    id: z.coerce.number(),
    placeId: z.string(),
    name: z.string(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    website: z.string().nullable(),
    categoryId: z.coerce.number(),
    address: z.string(),
});

type SchemaProps = z.infer<typeof schema>;

export const EditBusiness: React.FC<PropsInterface> = (props: PropsInterface) => {
  const {success, error: toastError} = useToast();
  const { data, loading } = useQuery(BUSINESS_BY_PLACE_ID, { fetchPolicy: 'network-only', variables: { placeId: props.business.placeId } });
  const { data: categoryData, loading: categoryLoading } = useQuery(CATEGORIES, {
        variables: {
          query: {
            isActive: {
              equals: true
            },
          }
        }
      });
  const [updateBusiness, updateResult] = useMutation(UPDATE_BUSINESS);

  const [values, setValues] = useState<any>({});

  useEffect(() => {
    if (data) {
      const { id, placeId, name, email, phone, website, categoryId, BusinessAddresses } = data.getBusinessByPlaceId;
      const address = BusinessAddresses?.length > 0 ? BusinessAddresses[0].formattedAddress : '';
      setValues({
        id,
        placeId,
        name,
        email,
        phone,
        website,
        categoryId,
        address,
      });
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SchemaProps>({
    values,
    resolver: zodResolver(schema),
  });

  console.log('errors', errors);

  const submitData = useCallback((values: any) => {
    console.log('value', values);
    const updateBusinessInput = {
      name: {'set': values.name },
      phone: {'set': values.phone },
      email: {'set': values.email },
      website: {'set': values.website },
      Category: {
        connect: {
          id: values.categoryId,
        }
      }
    };
    updateBusiness({
      variables: {
        id: Number(props.business.id),
        input: updateBusinessInput,
      }
    });
  },
  [props.business.id, updateBusiness]);

  useEffect(() => {
    if (updateResult.error) {
        toastError('Updating the business failed. Please try again.');
    } else if (updateResult.data) {
        success('Business updated successfully');
        props.handleClose(1);
    }
  }, [updateResult.error, updateResult.data, toastError, success, props]);

  if (loading || categoryLoading) {
    return (<></>);
  }

  const categories = categoryData.fetchCategories.categories.map((item: { category: string; id: string; }) => ({ label: item.category, value: Number(item.id) }));


  return (
    <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 3 }} variant="h4">Edit business</Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit((d) => submitData(d))}
        >
          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <Stack spacing={2}>
              <TextField InputLabelProps={{ shrink: true }} label="id" {...register('id')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="placeId" {...register('placeId')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="Name" {...register('name')} error={!!errors.name} helperText={errors.name?.message}  />
              <TextField multiline InputLabelProps={{ shrink: true }} label="Address" {...register('address')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="Email" {...register('email')} error={!!errors.email} helperText={errors.email?.message}  />
              <TextField InputLabelProps={{ shrink: true }} label="Phone" {...register('phone')} error={!!errors.phone} helperText={errors.phone?.message}  />
              <TextField InputLabelProps={{ shrink: true }} label="Website" {...register('website')} error={!!errors.website} helperText={errors.website?.message}  />
              <InputLabel id="category-label">Category</InputLabel>
              <Controller
                name="categoryId"
                control={control}
                render={(options) => (<Select
                  labelId="category-label"
                  id="category"
                  value={options.field.value ?? categories[0].value}
                  label="Category"
                  onChange={options.field.onChange}
                >
                  {categories.map((c: any) => (<MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>))}
                </Select>)}
              />
            </Stack>
          </div>
          <div style={{marginTop: '1em'}}>
            <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                Update
            </Button>

            <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
                Cancel
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
  );
}
