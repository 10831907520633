import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { VscAdd } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import { ErrorNotifier } from "../../common/error/ErrorNotifier";
import { useToast } from "../../common/hooks/useToast";
import { Popover } from "../../common/popover/Popover";
import { Table, columnTypes } from "../../common/table/Table";
import { CREATE_MANY_BUSINESS, DELETE_BUSINESS } from "../../graphql/mutation";
import { Item } from "../../styles/common";
import { AddBusiness } from "./AddBusiness";
import { HeaderLayout, columns } from "./business";
import { BUSINESSES } from "./businesses.queries";
import { BusinessFilter, BusinessFilters } from "./BusinessFilters";
import { EditBusiness } from "./EditBusiness";

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 25);

export const Businesses = () => {
  const inputRef = React.useRef<any>(null);
  let variables = React.useRef<any>({
    pagination: {
      skip: 0,
      take: perPage,
    },
  });
  const { error: toastError, success } = useToast();
  const [deleteBusiness, result] = useMutation(DELETE_BUSINESS);
  const [createManyBusiness, createManyBusinessResult] =
    useMutation(CREATE_MANY_BUSINESS);
  const { data, loading, error, refetch } = useQuery(BUSINESSES, { variables });

  const [id, setId] = useState<number | null>();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);
  const [business, setBusiness] = useState(null);
  const [openAddBusiness, setOpenAddBusiness] = useState(false);
  const [openEditBusiness, setOpenEditBusiness] = useState(false);

  useEffect(() => {
    variables.current = {
      pagination: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    };
    refetch(variables.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (result.error)
      toastError("Failed to delete Business. Please try again.");
    else if (result.data) {
      success("Business deleted successfully.");
      setOpen(false);
      setId(null);
      refetch(variables.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data, result.error, error]);

  useEffect(() => {
    if (createManyBusinessResult.error)
      toastError("Failed to create businesses. Please upload file again.");
    else if (createManyBusinessResult.data) {
      success("Businesses created successfully.");
      refetch(variables.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createManyBusinessResult.data, createManyBusinessResult.error]);

  const onDelete = (id: number) => {
    setId(Number(id));
    setOpen(true);
  };

  const editFn = (item: any) => {
    setBusiness(item);
    setOpenEditBusiness(true);
  };

  const navigate = useNavigate();

  const goToDetails = useCallback((item: any) => {
      navigate(`/businesses/${item.placeId}`);
  }, [navigate]);

  const onFilter = useCallback((filter: BusinessFilter) => {
          variables.current = {
              ...variables.current,
              filter: {
                  fieldFilter: {
                      filters: filter.filters,
                      operator: filter.operator,
                  },
              },
          };
          refetch(variables.current);
      }, [refetch]);

  if (loading) return <h4>Loading...</h4>;

  const businesses = data.getAllBusinesses.businesses.map((business: any) => {
    const recommendations = business.BusinessRecommendations.filter(
      (item: { isBlackFriendly: boolean }) => item.isBlackFriendly
    );
    return {
      ...business,
      address: business.BusinessAddresses[0].formattedAddress,
      noOfRecommendations: recommendations.length,
    };
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ErrorNotifier error={error} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ m: 2 }} variant="h4">
                Businesses
              </Typography>

              <HeaderLayout>
                <VscAdd
                  size={40}
                  style={{ cursor: "pointer" }}
                  title="Create a Business"
                  onClick={() => setOpenAddBusiness(true)}
                />

                {/* <input
                  type="file"
                  ref={inputRef}
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <BsUpload
                  size={40}
                  style={{ cursor: "pointer" }}
                  title="Upload Businesses"
                  onClick={() => inputRef.current.click()}
                /> */}
              </HeaderLayout>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12}>
            <Item>
                <BusinessFilters onFilter={onFilter} />
            </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Table
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              loading={loading || result.loading}
              columns={[
                ...columns,
                {
                  label: "",
                  key: "action",
                  textAlign: "right",
                  type: columnTypes.DETAILS,
                  actionFn: goToDetails,
                },
                {
                  label: "",
                  key: "action",
                  textAlign: "right",
                  type: columnTypes.EDIT,
                  actionFn: editFn,
                },
                { label: "", key: "action", textAlign: "right", type: columnTypes.DELETE },
              ]}
              onDelete={onDelete}
              total={data.getAllBusinesses.total}
              rows={businesses}
            />

            <Popover
              open={openAddBusiness}
              handleClose={() => {
                setOpenAddBusiness(false);
              }}
            >
              <AddBusiness
                handleClose={(isSuccess: number | undefined) => {
                  if (isSuccess) {
                    refetch(variables.current);
                  }
                  setOpenAddBusiness(false);
                }}
              />
            </Popover>

            <Popover
              open={openEditBusiness}
              handleClose={() => {
                setOpenEditBusiness(false);
              }}
            >
              <EditBusiness
                business={business}
                handleClose={(isSuccess: number | undefined) => {
                  if (isSuccess) {
                    refetch(variables.current);
                  }
                  setOpenEditBusiness(false);
                }}
              />
            </Popover>

            <Popover open={open} handleClose={() => setOpen(false)}>
              <Card sx={{ border: "1px solid black" }}>
                <div
                  style={{
                    display: "flex",
                    height: "60px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiOutlineExclamationCircle size={45} color="orange" />
                </div>
                <CardHeader title="Delete Business" />
                <CardContent>
                  <p>
                    Once deleted, the business cannot be recovered. Are you sure
                    you want to delete.
                  </p>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => deleteBusiness({ variables: { id: id } })}
                  >
                    Yes
                  </Button>
                </CardActions>
              </Card>
            </Popover>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
