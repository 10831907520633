import { gql } from '@apollo/client';

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $id: Int!
    $categoryUpdateInput: CategoryUpdateInput!
  ) {
    updateCategory(id: $id, categoryUpdateInput: $categoryUpdateInput) {
      id
    }
  }
`;
