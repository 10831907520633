import { styled } from '@mui/material';
import { ColumnInterface } from '../../common/table/Table';

export const HeaderLayout = styled('div')`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const columns: Array<ColumnInterface> = [
  { label: 'Name', key: 'name' },
  { label: 'Address', key: 'address' },
  { label: 'Phone', key: 'phone' },
  { label: 'No of Recommendations', key: 'noOfRecommendations' },
];
export const editBusinessOptions = [
  {
    key: 'name',
    label: 'Name',
    rules: {
      required: null,
      minLength: 2,
    },
    errorMessages: {
      required: 'Name is required',
      minLength: 'Name length should be more than or equal to 2.',
    },
  },
  {
    key: 'phone',
    label: 'Phone',
    rules: {
      required: null,
    },
    errorMessages: {
      required: 'Phone is required',
    },
  },
  {
    key: 'email',
    label: 'Email',
    rules: {
      minLength: 2,
    },
    errorMessages: {
      minLength: 'Email length should be more than or equal to 2.',
    },
  },
  {
    key: 'url',
    label: 'URL',
    rules: {
      minLength: 2,
    },
    errorMessages: {
      minLength: 'URL length should be more than or equal to 2.',
    },
  },
];
export const addBusinessOptions = (
  categories: Array<{ label: string; value: string }>,
) => [
  {
    key: 'name',
    label: 'Name',
    rules: {
      required: null,
      minLength: 2,
    },
    errorMessages: {
      required: 'Name is required',
      minLength: 'Name length should be more than or equal to 2.',
    },
  },
  {
    key: 'placeId',
    label: 'Business Id',
    type: 'number',
    rules: {
      required: null,
    },
    errorMessages: {
      required: 'Business Id is required',
    },
  },
  {
    key: 'email',
    label: 'Email',
    rules: {
      minLength: 2,
    },
    errorMessages: {
      minLength: 'Email length should be more than or equal to 2.',
    },
  },
  {
    key: 'url',
    label: 'URL',
    rules: {
      minLength: 2,
    },
    errorMessages: {
      minLength: 'URL length should be more than or equal to 2.',
    },
  },
  {
    key: 'phone',
    label: 'Phone',
    rules: {
      required: null,
    },
    errorMessages: {
      required: 'Phone is required',
    },
  },
  {
    type: 'select',
    key: 'category',
    label: 'Category',
    options: categories,
  },
  {
    type: 'Array:file',
    key: 'files',
    label: 'Business Images',
  },
];
export const addressFields = [
  {
    key: 'formattedAddress',
    label: 'Formatted Address',
    rules: {
      required: null,
      minLength: 2,
    },
    errorMessages: {
      required: 'Formatted Address is required',
      minLength: 'Formatted Address length should be more than or equal to 2.',
    },
  },
  {
    key: 'lat',
    type: 'number',
    label: 'Latitude',
    rules: {
      required: null,
    },
    errorMessages: {
      required: 'Latitude is required',
    },
  },
  {
    key: 'lng',
    type: 'number',
    label: 'Longitude',
    rules: {
      required: null,
    },
    errorMessages: {
      required: 'Longitude is required',
    },
  },
];
