import { gql } from '@apollo/client';

export const REQUEST_SMS_MUTATION = gql`
  mutation RequestSms($phone: String!) {
    requestSms(phone: $phone)
  }
`;

export const REQUEST_EMAIL_MUTATION = gql`
  mutation RequestEmail($email: String!) {
    requestEmail(email: $email)
  }
`;

export const VERIFY_CODE_MUTATION = gql`
  mutation VerifyCode(
    $code: String!
    $verificationSid: String!
    $phone: String!
    $email: String
  ) {
    verifyCode(
      code: $code
      verificationSid: $verificationSid
      phone: $phone
      email: $email
    )
  }
`;
