import { useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ErrorNotifier } from '../../common/error/ErrorNotifier';
import { Popover } from '../../common/popover/Popover';
import { columnTypes, Table } from "../../common/table/Table";
import { Item } from '../../styles/common';
import { EditUser } from './EditUser';
import { columns } from './user';
import { Filter, UserFilters } from './UserFilters';
import { USERS } from './users.queries';

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 5);

export const Users: React.FC<any> = (): JSX.Element => {
    let variables = React.useRef<any>({
        "pagination": {
            "skip": 0,
            "take": perPage
        }
    });
    const { data, loading, error, refetch } = useQuery(USERS, { variables });

    const [sortBy, setSortBy] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

    const [userToEdit, setUserToEdit] = useState<any>(null);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);

    React.useEffect(() => {
        variables.current = {
            pagination: {
                take: rowsPerPage,
                skip: page * rowsPerPage,
            }
        };

        if (sortBy) {
            variables.current = {
                ...variables.current,
                orderBy: sortBy,
            }
        }

        refetch(variables.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, sortBy]);

    const editFn = useCallback((item: any) => {
        setUserToEdit(item);
        setIsEditUserOpen(true);
    }, []);

    const navigate = useNavigate();

    const goToDetails = useCallback((item: any) => {
        navigate(`/users/${item.id}`);
    }, [navigate]);

    const onFilter = useCallback((filter: Filter) => {
        variables.current = {
            ...variables.current,
            filter: {
                fieldFilter: {
                    filters: filter.filters,
                    operator: filter.operator,
                },
                hasReferred: filter.usersThatHaveReferred,
                hasRecommendedABusiness: filter.usersThatHaveRecommended,
                hasRecommendedSameBusiness: filter.usersThatHaveRecommendedSameBusiness,
                visitedPage: filter.visitedPage,
                hasSharedReferralLink: filter.hasSharedReferralLink,
            },
        };
        refetch(variables.current);
    }, [refetch]);

    const handleSort = useCallback((sorting: any) => {
        setSortBy(sorting);
    }, []);

    if (loading) return <h4>Loading...</h4>;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Popover
                open={isEditUserOpen}
                handleClose={() => {
                  setIsEditUserOpen(false);
                }}
            >
                <EditUser
                    user={userToEdit}
                    handleClose={(isSuccess: number | undefined) => {
                        if (isSuccess) {
                          refetch(variables.current);
                        }
                        setIsEditUserOpen(false);
                      }}
                />
            </Popover>
            <ErrorNotifier error={error} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography sx={{ m: 2 }} variant="h4">Users</Typography>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <UserFilters onFilter={onFilter} />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Table
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            onSort={handleSort}
                            loading={loading}
                            columns={[
                                ...columns,
                                {
                                  label: "",
                                  key: "action",
                                  textAlign: "right",
                                  type: columnTypes.DETAILS,
                                  actionFn: goToDetails,
                                },
                                {
                                  label: "",
                                  key: "action",
                                  textAlign: "right",
                                  type: columnTypes.EDIT,
                                  actionFn: editFn,
                                },
                              ]}
                            rows={data.fetchUsers.users}
                            total={data.fetchUsers.total}
                        />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}