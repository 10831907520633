import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Item } from "../../styles/common";
import { BUSINESS_BY_PLACE_ID } from "./businessDetails.queries";

export const BusinessDetails: React.FC<any> = (): JSX.Element => {
  const params = useParams();
  const {data, loading, error} = useQuery(BUSINESS_BY_PLACE_ID, {
    variables: {
      placeId: params.placeId,
    }
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (<div>{error.message}</div>);
  }

  const businessDetails = data.getBusinessByPlaceId;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h3">
            {businessDetails.name}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={8}>
        <Item>
          <BusinessInfo businessDetails={businessDetails} />
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
        {businessDetails.BusinessImages?.length > 0 && (
            <div style={{ maxHeight: '200px', textAlign: 'center' }}>
              <img alt="profile" src={businessDetails.BusinessImages[0].Image.url} height={200} />
            </div>
          )}
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h5">Phone numbers</Typography>
          <PhoneHistory phoneHistory={businessDetails.PhoneHistory} />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h5">Recommended by</Typography>
          <Recommendations recommendations={businessDetails.BusinessRecommendations} />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h5">Skipped by</Typography>
          <Recommendations recommendations={businessDetails.SkippedBusinessRecommendation} />
        </Item>
      </Grid>
    </Grid>
  );
};

interface BusinessInfoProps {
  businessDetails: any;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({businessDetails}: BusinessInfoProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <strong>Name</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.name}
      </Grid>
      <Grid item xs={3}>
        <strong>Address</strong>
      </Grid>
      {businessDetails.BusinessAddresses?.length > 0 && (
        <Grid item xs={9}>
          {businessDetails.BusinessAddresses[0].formattedAddress}
        </Grid>
      )}
      <Grid item xs={3}>
        <strong>id</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.id}
      </Grid>
      <Grid item xs={3}>
        <strong>placeId</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.placeId}
      </Grid>
      <Grid item xs={3}>
        <strong>nationBuilderId</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.nationBuilderId}
      </Grid>
      <Grid item xs={3}>
        <strong>Email</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.email}
      </Grid>
      <Grid item xs={3}>
        <strong>Phone</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.phone}
      </Grid>
      <Grid item xs={3}>
        <strong>Category</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.Category.category}
      </Grid>
      <Grid item xs={3}>
        <strong>Website</strong>
      </Grid>
      <Grid item xs={9}>
        {businessDetails.website}
      </Grid>
      <Grid item xs={3}>
        <strong>Created at</strong>
      </Grid>
      <Grid item xs={9}>
        {new Date(businessDetails.createdAt).toLocaleString()}
      </Grid>
      <Grid item xs={3}>
        <strong>Updated at</strong>
      </Grid>
      <Grid item xs={9}>
        {new Date(businessDetails.updatedAt).toLocaleString()}
      </Grid>

    </Grid>
  )
};

interface RecommendationsProps {
  recommendations: any;

}

const Recommendations: React.FC<RecommendationsProps> = ({recommendations}: RecommendationsProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recommendations.map((r: any) => (
            <TableRow key={r.id}>
              <TableCell>{r.User?.username}</TableCell>
              <TableCell>{new Date(r.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface PhoneHistoryProps {
  phoneHistory: any;
}

const PhoneHistory: React.FC<PhoneHistoryProps> = ({phoneHistory}: PhoneHistoryProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {phoneHistory.map((r: any) => (
            <TableRow key={r.id}>
              <TableCell>{r.phone}</TableCell>
              <TableCell>{new Date(r.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};