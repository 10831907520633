import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { REQUEST_SMS_MUTATION, VERIFY_CODE_MUTATION } from './login.mutation';

export enum PhoneSignInStatus {
  initial,
  codeSent,
  verifying,
}

export enum PhoneSignInError {
  smsSendingFailure,
  otpVerificationFailure,
  captchaFailure,
}

export const useSignInWithPhoneNumber = (): [
  () => void,
  (phoneNumber: string) => void,
  (code: string) => void,
  () => void,
  PhoneSignInStatus,
  PhoneSignInError | null,
  string | null,
] => {
  const [signInStatus, setSignInStatus] = useState(PhoneSignInStatus.initial);
  const [loggedInUser, setLoggedInUser] = useState<string | null>(null);
  const [error, setError] = useState<PhoneSignInError | null>(null);
  const [smsVerificationSid, setSmsVerificationSid] = useState<string | null>(
    null,
  );
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const [
    requestSms,
    {
      data: requestSmsData,
      error: requestSmsError,
      loading: requestSmsLoading,
    },
  ] = useMutation(REQUEST_SMS_MUTATION);

  const [
    verifyOtpCode,
    {
      data: verifyCodeData,
      loading: verifyCodeLoading,
      error: verifyCodeError,
    },
  ] = useMutation(VERIFY_CODE_MUTATION);

  const initialize = useCallback(() => {}, []);

  const signInWithPhone = useCallback(
    (phone: string) => {
      setPhoneNumber(phone);
      requestSms({
        variables: {
          phone,
        },
      });
      setSignInStatus(PhoneSignInStatus.codeSent);
    },
    [requestSms],
  );

  useEffect(() => {
    if (requestSmsLoading || requestSmsError || !requestSmsData) {
      return;
    }
    if (requestSmsData) {
      setSmsVerificationSid(requestSmsData.requestSms);
    }
  }, [requestSmsData, requestSmsError, requestSmsLoading]);

  const verifyCode = useCallback(
    (code: string) => {
      setSignInStatus(PhoneSignInStatus.verifying);
      verifyOtpCode({
        variables: {
          phone: phoneNumber,
          verificationSid: smsVerificationSid,
          code,
        },
      });
    },
    [phoneNumber, smsVerificationSid, verifyOtpCode],
  );

  useEffect(() => {
    if (verifyCodeLoading || verifyCodeError || !verifyCodeData) {
      return;
    }
    setLoggedInUser(verifyCodeData.verifyCode);
  }, [verifyCodeData, verifyCodeError, verifyCodeLoading]);

  const reset = useCallback(() => {
    setSignInStatus(PhoneSignInStatus.initial);
    setError(null);
    initialize();
  }, [initialize]);

  return [
    initialize,
    signInWithPhone,
    verifyCode,
    reset,
    signInStatus,
    error,
    loggedInUser,
  ];
};
