import { gql } from '@apollo/client';

export const BUSINESS_BY_PLACE_ID = gql`
  query GetBusinessByPlaceId($placeId: String!) {
    getBusinessByPlaceId(placeId: $placeId) {
      id
      placeId
      name
      email
      url
      phone
      categoryId
      Category {
        category
      }
      placeId
      GoogleCategory {
        name
      }
      website
      createdAt
      updatedAt
      nationBuilderId
      PhoneHistory {
        phone
        createdAt
      }
      BusinessAddresses {
        formattedAddress
        country
        state
        city
      }
      BusinessImages {
        Image {
          url
        }
      }
      BusinessRecommendations {
        id
        isBlackFriendly
        createdAt
        User {
          id
          username
        }
      }
      SkippedBusinessRecommendation {
        id
        createdAt
        User {
          id
          username
        }
      }
    }
  }
`;
