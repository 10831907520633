import { gql } from '@apollo/client';

export const CREATE_BUSINESS_BY_PLACE_ID = gql`
  mutation createBusinessByPlaceId($placeId: String!) {
    createBusinessByPlaceId(placeId: $placeId) {
      id
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation updateBusiness($id: Int!, $input: BusinessUpdateInput!) {
    updateBusiness(id: $id, updateBusinessInput: $input) {
      id
      name
      email
    }
  }
`;
