import { gql } from '@apollo/client';

export const ACHIEVEMENTS = gql`
  query fetchAchievements(
    $pagination: PaginationInputType
    $orderBy: AchievementOrderByWithRelationInput
  ) {
    fetchAchievements(pagination: $pagination, orderBy: $orderBy) {
      achievements {
        id
        type
        title
        points
        description
      }
      total
    }
  }
`;
export const CATEGORIES = gql`
  query fetchCategories($query: CategoryWhereInput) {
    fetchCategories(query: $query) {
      categories {
        id
        category
      }
    }
  }
`;

export const FETCH_USERS_WITH_TOKEN = gql`
  query fetchUsersWithTokens(
    $pagination: PaginationInputType
    $where: UserWhereInput
  ) {
    fetchUsersWithTokens(where: $where, pagination: $pagination) {
      users {
        id
        UserToken {
          fcmToken
        }
        firstName
        lastName
      }
      total
    }
  }
`;

export const FETCH_USER_ROLE = gql`
  query fetchUserRole {
    userRole
  }
`;
