import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, colors, debounce } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useToast } from '../../common/hooks/useToast';
import { CATEGORIES } from '../../graphql/queries';
import { Item } from '../../styles/common';
import { CREATE_BUSINESS_BY_PLACE_ID } from './business.mutation';
import { BUSINESS_DETAILS_GOOGLE_PLACE, BUSINESS_GOOGLE_PLACE_AUTOCOMPLETE } from './businesses.queries';

interface PropsInterface {
    handleClose: (isSuccess?: number) => void;
}

export const AddBusiness: React.FC<PropsInterface> = (props: PropsInterface) => {
    const { success } = useToast();
    const navigate = useNavigate();
    const [categoryId, setCategoryId] = useState<number | null>(null);
    const [business, setBusiness] = useState<GoogleBusiness | null>(null);
    const [createBusinessByPlaceId, { loading: createLoading }] = useMutation(CREATE_BUSINESS_BY_PLACE_ID);

    const { data, loading } = useQuery(CATEGORIES, {
      variables: {
        query: {
          isActive: {
            equals: true
          },
        }
      }
    });

    const addBusiness = useCallback(async (placeId: string) => {
      await createBusinessByPlaceId({
        variables: {
          placeId,
        }
      });
      navigate(`/businesses/${placeId}`);
    }, [createBusinessByPlaceId, navigate]);

    useEffect(() => {
      if (loading || !data || !data.fetchCategories) {
        return;
      }
      setCategoryId(Number(data.fetchCategories.categories[0].id));
    }, [data, loading]);


    if (loading || !categoryId) return (
      <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
        <CircularProgress />
      </Container>
    );

    const categories = data.fetchCategories.categories.map((item: { category: string; id: string; }) => ({ label: item.category, value: Number(item.id) }));

    return (
        <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={categoryId}
              label="Category"
              onChange={(e) => setCategoryId(Number(e.target.value))}
            >
              {categories.map((c: any) => (<MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>))}
            </Select>
          </FormControl>
          {categoryId && (
            <BusinessAutocomplete categoryId={categoryId} onSelected={(business) => setBusiness(business)} />
          )}
          <div style={{height: '1rem'}}></div>
          {business && (
            <Grid container>
              <Grid item xs={12}>
                <Item>
                  <Grid container>
                    <Grid item xs={3}>
                      <strong>Name</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.name}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>Address</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.address}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>Email</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.email}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>Phone</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.phone}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>Website</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.website}
                    </Grid>
                    <Grid item xs={3}>
                      <strong>Image</strong>
                    </Grid>
                    <Grid item xs={9}>
                      {business.image && (<img src={business.image} width="200px"  alt={business.name} />)}
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          )}
          <div style={{height: '1rem'}}></div>
          {(business && business.isKnown) && (
            <Button variant="contained" onClick={() => navigate(`/businesses/${business.placeId}`)}>
              Business already exists in the DB. Visit its details.
            </Button>
          )}
          {(business && !business.isKnown) && (
            <Button variant="contained" onClick={() => addBusiness(business.placeId)} disabled={createLoading}>
              Add business
            </Button>
          )}
          <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
              Cancel
          </Button>
        </Container>
    );
};


interface PlaceType {
  placeId: string;
  description: string;
}

interface GoogleBusiness {
  placeId: string;
  isKnown: boolean;
  name: string;
  address: string;
  phone?: string;
  website?: string;
  email?: string;
  image?: string;
}

  interface BusinessAutocompleteProps {
    categoryId: number,
    onSelected: (business: GoogleBusiness) => void;
  }

  const BusinessAutocomplete: React.FC<BusinessAutocompleteProps> = (props: BusinessAutocompleteProps) : JSX.Element => {
    const [value, setValue] = useState<PlaceType | null>(null)
    const [inputValue, setInputValue] = useState('');
    const [getOptions, { data, loading }] = useLazyQuery(BUSINESS_GOOGLE_PLACE_AUTOCOMPLETE);
    const [getBusinessDetails, { data: businessDetailsData, }] = useLazyQuery(BUSINESS_DETAILS_GOOGLE_PLACE);
    const [options, setOptions] = useState<PlaceType[]>([]);

    const fetch = useMemo(() => debounce((filter: string) => {
      getOptions({
        variables: {
          filter: !!filter ? filter : null,
          categoryId: props.categoryId,
        }
      });
    }, 400), [getOptions, props.categoryId]);


    useEffect(() => {
      fetch(inputValue);
    }, [fetch, inputValue]);

    useEffect(() => {
      if (loading) {
        return;
      }
      if (data) {
        setOptions(data.googleBusinessAutocomplete);
      }
    }, [data, loading,]);

    useEffect(() => {
      if (value?.placeId) {
        getBusinessDetails({
          variables: {
            placeId: value.placeId,
          }
        });
      }
    }, [getBusinessDetails, value]);

    useEffect(() => {
      if (businessDetailsData) {
        props.onSelected({
          ...businessDetailsData.googleBusinessByPlaceId,
          placeId: value!.placeId,
        });
      }
    }, [businessDetailsData, props]);

    return (
      <Autocomplete
          options={options}
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText={'Search business'}
          value={value}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event: any, newValue) => {
            if (newValue) {
              setValue(newValue);
            }
          }}
          getOptionLabel={(option: any) => option.description}
          renderInput={(params) => (
            <TextField {...params} label="Business" fullWidth />
          )}
          renderOption={(props: any, option) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Grid container sx={{ alignItems: 'center' }}>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Box>
                        {option.description}
                      </Box>

                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
    );
  }