import { Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { Header } from '../common/header/Header';
import { Sidebar } from '../common/sidebar/Sidebar';

import { getTokens } from '../common/manage_tokens';
import { Achievements } from '../pages/achievements/Achievements';
import { BusinessDetails } from '../pages/businessDetails/BusinessDetails';
import { Businesses } from '../pages/businesses/Businesses';
import { Categories } from '../pages/categories/Categories';
import { Notification } from '../pages/notification/Notification';
import { NotificationWithFilters } from '../pages/notification/NotificationWithFilters';
import { PageNotFound } from '../pages/pageNotFound/PageNotFound';
import { UserDetails } from '../pages/userDetails/UserDetails';
import { Users } from '../pages/users/Users';

const HEADER_HEIGHT = '4rem';
const HEADER_HEIGHT_SPACE = '0.15rem';
const SIDEBAR_WIDTH = '300px';

const Layout = styled('div')(({ width }: { width: string | number }) => ({
  width: `calc( 100% - ${width} )`,
  margin: '2rem',
}));

export const DashboardRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getTokens();
    if (!token) {
      navigate('/account/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sidebarToggle, setSidebarToggle] = useState(true);
  const width = sidebarToggle ? SIDEBAR_WIDTH : 0;

  return (
    <Box sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'grey.100',
    }}>
      <Header headerHeight={HEADER_HEIGHT} setToggle={setSidebarToggle} toggle={sidebarToggle} />
      <div style={{ display: 'flex', marginTop: HEADER_HEIGHT_SPACE }}>
        <Sidebar width={width} />

        <Layout width={width}>
            <Routes>
                <Route path="/" element={<PageNotFound />} />
                <Route path="/users/:userId" element={<UserDetails />} />
                <Route path="/users" element={<Users />} />
                <Route path="/achievements" element={<Achievements />} />
                <Route path="/businesses" element={<Businesses />} />
                <Route path="/businesses/:placeId" element={<BusinessDetails />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/notification-new" element={<NotificationWithFilters />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
        </Layout>
      </div>
    </Box>
  );
};
