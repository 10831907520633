import { useQuery } from "@apollo/client";
import { Box, colors, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Popover } from "../../common/popover/Popover";
import { Item } from "../../styles/common";
import { ALL_CATEGORIES } from "./categories.queries";
import { EditCategory } from "./EditCategory";

export const Categories: React.FC<any> = (): JSX.Element => {
  const { data, loading, error, refetch } = useQuery(ALL_CATEGORIES);
  const [categoryToEdit, setCategoryToEdit] = useState<any>(null);
  const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);

  const editFn = useCallback((item: any) => {
    setCategoryToEdit(item);
    setIsEditCategoryOpen(true);
  }, []);

  if (loading) return <h4>Loading...</h4>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Popover
          open={isEditCategoryOpen}
          handleClose={() => {
            setIsEditCategoryOpen(false);
          }}
      >
        <EditCategory
          category={categoryToEdit}
          handleClose={(isSuccess: number | undefined) => {
            if (isSuccess) {
              refetch();
            }
            setIsEditCategoryOpen(false);
          }}
        />
      </Popover>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography sx={{ m: 2 }} variant="h4">Categories</Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Google categories</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Updated at</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.fetchCategories.categories.map((category: any) => (
                    <TableRow key={category.id}>
                      <TableCell>{category.id}</TableCell>
                      <TableCell>{category.category}</TableCell>
                      <TableCell>{category.isActive ? 'true' : 'false'}</TableCell>
                      <TableCell>{category.GoogleCategoryMappings.map((m: any) => m.googleCategoryName).join(', ')}</TableCell>
                      <TableCell>{new Date(category.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{new Date(category.updatedAt).toLocaleString()}</TableCell>
                      <TableCell>
                        <BsFillPencilFill
                            color={colors.grey[500]}
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              editFn(category);
                            }}
                          />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}