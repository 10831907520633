import { useLazyQuery } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../common/hooks/useToast";
import { saveTokens } from "../../common/manage_tokens";
import { FETCH_USER_ROLE } from "../../graphql/queries";
import { EmailSignInError, EmailSignInStatus, useSignInWithEmail } from "./useSignInWithEmail";
import { PhoneSignInError, PhoneSignInStatus, useSignInWithPhoneNumber } from "./useSignInWithPhoneNumber";

interface PropsInterface {
  loginMethod: string;
}

export const LoginDetails: React.FC<PropsInterface> = ({
  loginMethod,
}: PropsInterface) => {
  return loginMethod === "email" ? (<LoginWithEmail />) : (<LoginWithPhoneNumber />);
};

const LoginWithEmail = () => {
  const navigate = useNavigate();
  const { error: toastError, success } = useToast();
  const [email,setEmail] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [initialize, signInWithEmail, verifyCode, reset, signInStatus, error, token] = useSignInWithEmail();
  const [getUserRole, { data: userRole, error: userRoleError, },] = useLazyQuery(FETCH_USER_ROLE);

  const updateEmail = useCallback((event: any) => {
    setEmail(event.target.value);
  }, []);
  const updatePhoneNumber = useCallback((event: any) => {
    setPhoneNumber(event.target.value);
  }, []);

  const updateOtpCode = useCallback((event: any) => {
    setOtpCode(event.target.value);
  }, []);

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    if (signInStatus === EmailSignInStatus.initial) {
      signInWithEmail(email, phoneNumber);
      return;
    }
    if (signInStatus === EmailSignInStatus.codeSent) {
      verifyCode(otpCode);
      return;
    }
  }, [signInStatus, signInWithEmail, email, phoneNumber, verifyCode, otpCode]);

  useEffect(() => {
    if (token) {
      saveTokens(token);
      getUserRole();
    }
  }, [getUserRole, token]);

  useEffect(() => {
    if (userRoleError) {
      toastError("Login failed. Please try again");
      reset();
      return;
    }

    if (userRole && userRole.userRole === 'ADMIN') {
      success("Login Successful!");
        navigate("/users");
        return;
    } else if (userRole) {
      toastError("Login failed. Please try again");
      reset();
    }
  }, [toastError, userRoleError, navigate, success, userRole, reset]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (error === EmailSignInError.emailSendingFailure) {
      toastError('Error while sending email.');
    }
    if (error === EmailSignInError.otpVerificationFailure) {
      toastError('Error while verifying code.');
    }
  }, [error, toastError]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {signInStatus === EmailSignInStatus.initial &&
          <>
            <TextField
              fullWidth
              label="Email"
              name="email"
              onChange={updateEmail}
              required
              value={email}
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              onChange={updatePhoneNumber}
              required
              value={phoneNumber}
            />
            <Button
            id="sign-in-with-phone"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          </>
        }
        {signInStatus === EmailSignInStatus.codeSent &&
          <>
          <TextField
            fullWidth
            label="Code"
            name="code"
            onChange={updateOtpCode}
            required
            value={otpCode}
            />
            <Button
            id="send-otp-code"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
            </>
        }

      </Stack>
    </form>
  );
};

const LoginWithPhoneNumber = () => {
  const navigate = useNavigate();
  const { error: toastError, success } = useToast();
  const [phoneNumber,setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [initialize, signInWithPhone, verifyCode, reset, signInStatus, error, token] = useSignInWithPhoneNumber();
  const [getUserRole, { data: userRole, error: userRoleError, },] = useLazyQuery(FETCH_USER_ROLE);

  const updatePhoneNumber = useCallback((event: any) => {
    setPhoneNumber(event.target.value);
  }, []);

  const updateOtpCode = useCallback((event: any) => {
    setOtpCode(event.target.value);
  }, []);

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    if (signInStatus === PhoneSignInStatus.initial) {
      signInWithPhone(phoneNumber);
      return;
    }
    if (signInStatus === PhoneSignInStatus.codeSent) {
      verifyCode(otpCode);
      return;
    }
  }, [signInStatus, phoneNumber, signInWithPhone, otpCode, verifyCode]);

  useEffect(() => {
    if (token) {
      saveTokens(token);
      getUserRole();
    }
  }, [getUserRole, token]);

  useEffect(() => {
    if (userRoleError) {
      toastError("Login failed. Please try again");
      reset();
      return;
    }

    if (userRole && userRole.userRole === 'ADMIN') {
      success("Login Successful!");
        navigate("/users");
        return;
    } else if (userRole) {
      toastError("Login failed. Please try again");
      reset();
    }
  }, [toastError, userRoleError, navigate, success, userRole, reset]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (error === PhoneSignInError.smsSendingFailure) {
      toastError('Error while sending SMS.');
    }
    if (error === PhoneSignInError.otpVerificationFailure) {
      toastError('Error while verifying code.');
    }
  }, [error, toastError]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {signInStatus === PhoneSignInStatus.initial &&
          <>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              onChange={updatePhoneNumber}
              required
              value={phoneNumber}
            />
            <Button
            id="sign-in-with-phone"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          </>
        }
        {signInStatus === PhoneSignInStatus.codeSent &&
          <>
          <TextField
            fullWidth
            label="Code"
            name="code"
            onChange={updateOtpCode}
            required
            value={otpCode}
            />
            <Button
            id="send-otp-code"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
            </>
        }

      </Stack>
    </form>
  );
};