import { gql } from '@apollo/client';

export const BUSINESSES = gql`
  query getAllBusinesses(
    $query: BusinessInputType
    $filter: BusinessFilterInputType
    $pagination: PaginationInputType
  ) {
    getAllBusinesses(query: $query, filter: $filter, pagination: $pagination) {
      businesses {
        id
        placeId
        name
        email
        url
        phone
        BusinessRecommendations {
          id
          isBlackFriendly
        }
        BusinessAddresses {
          id
          formattedAddress
          lat
          lng
        }
      }
      total
    }
  }
`;

export const BUSINESS_FIELDS = gql`
  query {
    fetchBusinessFields {
      fields {
        name
        type
        kind
        isReference
      }
    }
  }
`;

export const BUSINESS_COUNTRY_FILTER_AUTOCOMPLETE = gql`
  query countryAutocomplete($filter: String) {
    getBusinessCountriesAutocomplete(filter: $filter) {
      country
    }
  }
`;

export const BUSINESS_STATE_FILTER_AUTOCOMPLETE = gql`
  query stateFilterAutocomplete($filter: String) {
    getBusinessStatesAutocomplete(filter: $filter) {
      state
      country
    }
  }
`;

export const BUSINESS_CITY_FILTER_AUTOCOMPLETE = gql`
  query cityFilterAutocomplete($filter: String) {
    getBusinessCitiesAutocomplete(filter: $filter) {
      city
      state
      country
    }
  }
`;

export const BUSINESS_GOOGLE_PLACE_AUTOCOMPLETE = gql`
  query googleBusinessAutocomplete($filter: String, $categoryId: Int!) {
    googleBusinessAutocomplete(filter: $filter, categoryId: $categoryId) {
      placeId
      description
    }
  }
`;

export const BUSINESS_DETAILS_GOOGLE_PLACE = gql`
  query googleBusinessByPlaceId($placeId: String!) {
    googleBusinessByPlaceId(placeId: $placeId) {
      isKnown
      name
      address
      phone
      website
      email
      image
    }
  }
`;
