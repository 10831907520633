import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Chip, colors, Container, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from 'zod';
import { useToast } from "../../common/hooks/useToast";
import { UPDATE_CATEGORY } from "./categories.mutation";
import { ALL_GOOGLE_CATEGORIES } from "./categories.queries";

interface PropsInterface {
  category: any;
  handleClose: (isSuccess?: number) => void;
}

const schema = z.object({
  id: z.coerce.number(),
  category: z.string().min(1, { message: 'Required' }),
  imageUrl: z.string().url(),
  isActive: z.boolean(),
  googleCategories: z.array(z.string()),
});

type SchemaProps = z.infer<typeof schema>;

export const EditCategory: React.FC<PropsInterface> = (props: PropsInterface) => {
  const {data: googleCategoryData, loading: googleCategoriesLoading} = useQuery(ALL_GOOGLE_CATEGORIES);

  const [updateCategory, updateResult] = useMutation(UPDATE_CATEGORY);

  const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      watch,
    } = useForm<SchemaProps>({
      values: {
        id: props.category.id,
        category: props.category.category,
        imageUrl: props.category.imageUrl,
        isActive: props.category.isActive,
        googleCategories: props.category.GoogleCategoryMappings.map((c: any) => c.googleCategoryName),
      },
      resolver: zodResolver(schema),
    });

  const submitData = useCallback(async (values: any) => {
    console.log('d', props.category.GoogleCategoryMappings
      .filter((c: any) => !values.googleCategories.includes(c.googleCategoryName))
      .map((c:any) => ({ googleCategoryName: c.googleCategoryName })));

    const disconnect = props.category.GoogleCategoryMappings
    .filter((c: any) => !values.googleCategories.includes(c.googleCategoryName))
    .map((c:any) => ({ googleCategoryName: c.googleCategoryName }));

    await updateCategory({
      variables: {
        id: Number(props.category.id),
        categoryUpdateInput: {
          category: { "set": values.category },
          imageUrl: { "set": values.imageUrl },
          isActive: { "set": values.isActive },
          GoogleCategoryMappings: {
            "connect": values.googleCategories.map((c: string) => ({ googleCategoryName: c })),
          },
        },
      }
    });


  }, [props.category.GoogleCategoryMappings, props.category.id, updateCategory]);

  const {success, error: toastError} = useToast();

  useEffect(() => {
    if (updateResult.error) {
      toastError('Updating the category failed. Please try again.');
    } else if (updateResult.data) {
      success('Category updated successfully');
      props.handleClose(1);
    }
  }, [updateResult.error, updateResult.data, toastError, success, props]);

  if (googleCategoriesLoading) {
    return (<></>);
  }

  const imageUrl = watch('imageUrl');


  return (
    <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 3 }} variant="h4">Edit category</Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit((d) => submitData(d))}
        >
          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <Stack spacing={2}>
              <TextField InputLabelProps={{ shrink: true }} label="id" {...register('id')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="Name" {...register('category')} error={!!errors.category} helperText={errors.category?.message}/>
              <TextField InputLabelProps={{ shrink: true }} label="Image" {...register('imageUrl')} error={!!errors.imageUrl} helperText={errors.imageUrl?.message}/>
              {imageUrl && <img src={imageUrl} width={200} alt="category" />}
              <Controller
                name="isActive"
                control={control}
                render={(options) => (
                  <FormControlLabel control={
                    <Switch
                      checked={options.field.value ?? false}
                      onChange={options.field.onChange}
                    />
                  } label="Label" />
                )}
              />
              <InputLabel id="google-category-label">Google category</InputLabel>
              <Controller
                name="googleCategories"
                control={control}
                render={(options) => (<Select
                  multiple
                  labelId="google-category-label"
                  id="google-category"
                  value={options.field.value ?? []}
                  label="Google category"
                  onChange={(e) => {
                    options.field.onChange(e);
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {(googleCategoryData.fetchGoogleCategoryMappings ?? []).map((c: any) => (<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>))}
                </Select>)}
              />
            </Stack>
          </div>
          <div style={{marginTop: '1em'}}>
            <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                Update
            </Button>

            <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
                Cancel
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
  );
};